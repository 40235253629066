import classNames from 'classnames'
import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {classes} from './no-events-text.st.css'

export const NoEventsText = props => (
  <Text
    typography={TextTypography.smallTitle}
    {...props}
    className={classNames(classes.root, {[classes.mobile]: props.mobile})}
  />
)
