import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionCallbacks from '../../containers/action-callbacks/action-callbacks';
import { WidgetPerformanceLoggers } from '../../containers/performance-loggers/performance-loggers';
import PaymentEvents from '../../components/payment-events/payment-events';
import OpenFullscreenModalShare from '../../containers/open-fullscreen-modal-share/open-fullscreen-modal-share';
import LiveStartHandler from '../../components/live-start-handler/live-start-handler';
import VideoList from '../../components/video-list/video-list';
import { withPlayerModuleLoader } from '../../data-components/player-module-loader';
/* components */
import { getThumbnailMinWidthAttribute } from '@wix/wix-vod-shared/components';
import { CssSlider } from '../../components/css-slider/CssSlider';
import ActionBar from '../../components/action-bar/action-bar';
import VideoThumbnail from '../../components/video-thumbnail/video-thumbnail';
import NoResults from '../components/no-results/no-results';

/* utils */
import { appStatus } from '../../services/app-status';

import { logWidgetSystem } from '../../worker/actions/bi';
import { logWidgetVidClick } from '../../utils/bi/widget-common-events';

import { getCompId } from '../../redux/hydrated-data/hydrated-data';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import {
  isVideoPlayingOptimistic,
  isVideoPausedOptimistic,
} from '../../selectors/video-playback-status';
import { getVideosGroupedByIds } from '../../selectors/videos';
import {
  getVideoIds,
  getCursor,
  getIsFetching,
} from '../../redux/lazy-channel-videos/selectors';
import { getCategory } from '../../selectors/search';
import {
  isThumbnailsPreviewHover,
  isSliderNavAlwaysVisible,
  getThumbnailSpacing,
  getContainerMargins,
  getChannelLayout,
  isPlayInFrame,
  isRTL,
  isResponsiveEditor,
  isNavigationArrowsOutside,
} from '../../selectors/app-settings';
import { getMainVideo } from '../../selectors/get-video';
import { getCurrentSiteUser } from '../../selectors/current-site-user';
import { getSliderHorizontalMargin } from './selectors';
import { isInlineShareVisible } from '../../selectors/inline-share';
import { showAutoPlay } from '../../selectors/layout';
import {
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
} from '../../redux/actions/full-screen-modal';
import {
  loadMore,
  loadMoreVideoPages,
} from '../../redux/lazy-channel-videos/actions';
import { selectVideo } from '../../redux/actions/select-video';
import { pauseVideo } from '../../redux/actions/player/change-playback-status';
import { resetSearch } from '../../redux/actions/search';
import { requestPlayVideo } from '../../redux/actions/request-play-video';
import {
  BIG_SLIDER_THUMBNAIL_WIDTH,
  NAV_BUTTON_WIDTH,
  SMALL_SLIDER_THUMBNAIL_WIDTH,
} from './constants';
import { CHANNEL_LAYOUT_VALUES } from '@wix/wix-vod-constants/app-settings';
import { withTranslation, withFedopsLogger } from '@wix/yoshi-flow-editor';
import styles from './slider.scss';
import * as viewModeSelectors from '../../selectors/view-mode';
import { SliderEmptyState } from '../../components/slider-empty-state/slider-empty-state';
import { isMobile } from '../../selectors/form-factor';
import { getSliderHorizontalPadding } from './utils/layout-calculations';
import { withWindowSize } from '../../containers/window-size';

const channelLayoutToNameMap = {
  [CHANNEL_LAYOUT_VALUES.SLIDER_SMALL]: 'sliderSmall',
  [CHANNEL_LAYOUT_VALUES.SLIDER_BIG]: 'sliderBig',
};

const mapStateToProps = (state, props) => {
  const mainVideo = getMainVideo(state);
  const videoIds = getVideoIds(state);
  const channelLayout = getChannelLayout(state);
  const mainVideoId = _.get(mainVideo, 'id');
  const isArrowsOutside = isNavigationArrowsOutside(state);
  const isSmallSlider = channelLayout === CHANNEL_LAYOUT_VALUES.SLIDER_SMALL;
  const itemWidth = isSmallSlider
    ? SMALL_SLIDER_THUMBNAIL_WIDTH
    : BIG_SLIDER_THUMBNAIL_WIDTH;
  const thumbnailHeight = (itemWidth / 16) * 9;

  const sliderPadding = getSliderHorizontalPadding(itemWidth, isArrowsOutside);

  return {
    styleId: getCompId(state),
    isEditorMode: viewModeSelectors.isEditorMode(state),
    isResponsive: isResponsiveEditor(state),
    channel: getChannelForWidget(state),
    channelLayout,
    layoutName: _.get(channelLayoutToNameMap, channelLayout, ''),
    currentSiteUser: getCurrentSiteUser(state),
    mainVideo,
    mainVideoId,
    mainVideoIndex: _.indexOf(videoIds, mainVideoId),
    videoIds,
    videoByIds: getVideosGroupedByIds(state),
    nextVideosCursor: getCursor(state),
    isFetching: getIsFetching(state),
    isThumbnailsPreviewHover: isThumbnailsPreviewHover(state),
    isNavAlwaysVisible: isSliderNavAlwaysVisible(state),
    isVideoPlaying: isVideoPlayingOptimistic(state),
    isVideoPaused: isVideoPausedOptimistic(state),
    thumbnailSpacing: getThumbnailSpacing(state),
    containerMargins: getContainerMargins(state),
    selectedCategory: getCategory(state),
    isInlineShareVisible: isInlineShareVisible(state, props),
    isPlayInFrame: isPlayInFrame(state),
    showAutoPlay: showAutoPlay(state),
    isRTL: isRTL(state),
    isMobile: isMobile(state),
    isArrowsOutside: isNavigationArrowsOutside(state),
    sliderMargin: getSliderHorizontalMargin(state),
    sliderWidth: props.windowSize.width,
    sliderPadding,
    itemWidth,
    thumbnailHeight,
  };
};

const mapDispatchToProps = {
  loadMore,
  loadMoreVideoPages,
  selectVideo,
  pauseVideo,
  resetSearch,
  requestPlayVideo,
  openFullScreenVideoOverlay,
  closeFullScreenVideoOverlay,
  logWidgetSystem,
  logWidgetVidClick,
};

const SliderLayoutComponent = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    class SliderLayout extends React.Component {
      static propTypes = {
        styleId: PropTypes.string,
        channel: PropTypes.object.isRequired,
        layoutName: PropTypes.string.isRequired,
        currentSiteUser: PropTypes.object,
        isEditorMode: PropTypes.bool.isRequired,

        mainVideo: PropTypes.object,
        mainVideoId: PropTypes.string,
        mainVideoIndex: PropTypes.number,

        videoIds: PropTypes.array.isRequired,
        videoByIds: PropTypes.object.isRequired,
        nextVideosCursor: PropTypes.string,
        isFetching: PropTypes.bool.isRequired,
        loadMore: PropTypes.func.isRequired,
        loadMoreVideoPages: PropTypes.func.isRequired,
        selectVideo: PropTypes.func.isRequired,
        pauseVideo: PropTypes.func.isRequired,
        resetSearch: PropTypes.func.isRequired,
        requestPlayVideo: PropTypes.func.isRequired,
        channelLayout: PropTypes.number.isRequired,

        isThumbnailsPreviewHover: PropTypes.bool.isRequired,
        isNavAlwaysVisible: PropTypes.bool.isRequired,
        isVideoPlaying: PropTypes.bool.isRequired,
        isVideoPaused: PropTypes.bool.isRequired,
        isResponsive: PropTypes.bool.isRequired,

        containerMargins: PropTypes.number.isRequired,
        thumbnailSpacing: PropTypes.number.isRequired,
        isArrowsOutside: PropTypes.bool.isRequired,
        itemWidth: PropTypes.number.isRequired,
        thumbnailHeight: PropTypes.number.isRequired,
        sliderMargin: PropTypes.number.isRequired,
        sliderPadding: PropTypes.number.isRequired,
        isInlineShareVisible: PropTypes.bool.isRequired,
        isPlayInFrame: PropTypes.bool.isRequired,
        showAutoPlay: PropTypes.bool,

        selectedCategory: PropTypes.string,

        openFullScreenVideoOverlay: PropTypes.func.isRequired,
        closeFullScreenVideoOverlay: PropTypes.func.isRequired,

        PlayerComponent: PropTypes.func,
        isPortableDevice: PropTypes.bool,
        isRTL: PropTypes.bool,
      };

      componentDidMount() {
        appStatus.setMarkerReady(this.props.channelLayout);
      }

      componentDidUpdate() {
        this.props.logWidgetSystem('videoList.searchByCategory.rendered', {
          previousEventName: 'videoList.searchByCategory.requested',
        });
      }

      logVideoPlayRequested = (videoItem) => {
        const { channel } = this.props;
        this.props.logWidgetVidClick({ videoItem, channelData: channel });
      };

      renderItem = (videoId) => {
        const {
          channel,
          videoByIds,
          currentSiteUser,
          itemWidth,
          mainVideo,
          thumbnailHeight,
        } = this.props;

        if (!videoId) {
          return null;
        }

        const video = videoByIds[videoId];

        const dataHook = classnames('video-list-thumb-wrapper', {
          'video-list-thumb-wrapper-selected': mainVideo.id === videoId,
        });

        return (
          <ActionCallbacks
            channelId={channel.id}
            videoItem={video}
            onPlayRequestedBi={this.logVideoPlayRequested}
            key={videoId}
          >
            <VideoThumbnail
              videoItem={video}
              key={videoId}
              dataHook={dataHook}
              channelData={channel}
              isContentFocusable
              currentSiteUser={currentSiteUser}
              width={itemWidth}
              textClassName={styles.videoDescription}
              breakpoints={[
                {
                  min: 0,
                  width: itemWidth,
                  height: thumbnailHeight,
                },
              ]}
            />
          </ActionCallbacks>
        );
      };

      getSliderAreaStyles = () => {
        const { sliderMargin, sliderPadding } = this.props;
        return {
          width: `calc(100% - ${(sliderMargin + sliderPadding) * 2}px)`,
        };
      };

      loadMoreItems = (itemsToLoadCount) => {
        const {
          loadMore,
          loadMoreVideoPages,
          selectedCategory,
          isFetching,
          nextVideosCursor,
        } = this.props;

        const canLoadMore = Boolean(nextVideosCursor && !isFetching);

        if (!canLoadMore) {
          return;
        }

        if (selectedCategory) {
          loadMoreVideoPages();
          return;
        }

        loadMore(itemsToLoadCount, { category: selectedCategory });
      };

      getPublishedChannelItemsCount() {
        const { channel, videoIds, nextVideosCursor } = this.props;

        return !nextVideosCursor && videoIds.length
          ? videoIds.length
          : channel.videosCount;
      }

      getMaxItemsCount() {
        const {
          videoIds,
          selectedCategory,
          nextVideosCursor,
          sliderWidth,
          itemWidth,
        } = this.props;

        const itemsPerPage = Math.ceil(sliderWidth / itemWidth);

        if (!selectedCategory || !nextVideosCursor) {
          return this.getPublishedChannelItemsCount();
        }

        return Math.max(
          videoIds.length + (nextVideosCursor ? 1 : 0),
          itemsPerPage,
        );
      }

      renderSlider() {
        const {
          channel,
          videoIds,
          isArrowsOutside,
          isNavAlwaysVisible,
          thumbnailSpacing,
          isRTL,
          thumbnailHeight,
        } = this.props;

        return (
          <CssSlider
            dataHook="css-slider"
            items={videoIds}
            itemsCount={this.getMaxItemsCount()}
            renderItem={this.renderItem}
            loadMore={this.loadMoreItems}
            isRTL={isRTL}
            itemsGap={thumbnailSpacing}
            a11y={{
              containerMessage: this.props.t(
                'widget.accessibility.channel-videos',
                {
                  channelTitle: channel.title,
                },
              ),
              prevSlideMessage: this.props.t(
                'widget.accessibility.prev-videos',
              ),
              nextSlideMessage: this.props.t(
                'widget.accessibility.next-videos',
              ),
            }}
            navButtonStyle={{
              height: thumbnailHeight,
              width: NAV_BUTTON_WIDTH,
            }}
            navButtonClassName={classnames({
              [styles.navButtonOutside]: isArrowsOutside,
            })}
            navButtonsAbsolute={!isArrowsOutside}
            showArrowsOnHover={!isNavAlwaysVisible}
          />
        );
      }

      renderEmptyState() {
        return (
          <div
            data-hook="slider-empty"
            style={this.getSliderAreaStyles()}
            className={styles.empty}
          >
            {!this.props.isFetching && (
              <div className={styles.emptyContent}>
                {this.props.t('widget.this-channel-is-coming-soon')}
              </div>
            )}
          </div>
        );
      }

      renderContent() {
        const {
          mainVideo,
          selectedCategory,
          nextVideosCursor,
          videoIds,
          isFetching,
        } = this.props;

        if (
          !isFetching &&
          selectedCategory &&
          !nextVideosCursor &&
          !videoIds.length
        ) {
          return this.renderEmptySearchState(
            this.props.t('widget.categories.no-videos-in-category'),
          );
        }

        return mainVideo ? this.renderSlider() : this.renderEmptyState();
      }

      renderEmptySearchState(message) {
        return (
          <div style={this.getSliderAreaStyles()}>
            <NoResults
              dataHook="slider-empty-search-results"
              message={message}
              onButtonClick={this.props.resetSearch}
            />
          </div>
        );
      }

      renderActions() {
        const { isArrowsOutside, channel, isResponsive, videoIds, isFetching } =
          this.props;

        const style = isResponsive
          ? null
          : {
              margin: isArrowsOutside ? `0 ${NAV_BUTTON_WIDTH}px` : 0,
            };

        if (isResponsive && !videoIds.length && !isFetching) {
          return null;
        }

        return (
          <ActionBar
            onPageRefresh={_.noop}
            style={style}
            channelData={channel}
            isResponsive={isResponsive}
          />
        );
      }

      renderSliderContent() {
        const { selectedCategory } = this.props;

        return (
          <section
            key={`slider-${selectedCategory}`}
            data-hook="slider-container"
          >
            {this.renderContent()}
          </section>
        );
      }

      renderResponsiveSliderContent() {
        const { videoIds, videoByIds, channel, currentSiteUser, isFetching } =
          this.props;

        if (!isFetching && !videoIds.length) {
          return (
            <div>
              <SliderEmptyState />
            </div>
          );
        }

        return (
          <VideoList
            videoIds={videoIds}
            videoByIds={videoByIds}
            itemsCount={this.getMaxItemsCount()}
            channel={channel}
            currentSiteUser={currentSiteUser}
            onPlayRequestedBi={this.logVideoPlayRequested}
          />
        );
      }

      setCurrentVideoFromPayment = ({ itemId } = {}) => {
        if (itemId) {
          this.props.selectVideo(itemId);
        }
      };

      playVideo = ({ id }) => {
        const {
          channel,
          requestPlayVideo,
          openFullScreenVideoOverlay,
          closeFullScreenVideoOverlay,
          isPlayInFrame,
          isPortableDevice,
        } = this.props;

        if (isPlayInFrame || isPortableDevice) {
          requestPlayVideo(id);
          return;
        }

        openFullScreenVideoOverlay(
          channel.id,
          id,
          true,
          closeFullScreenVideoOverlay,
        );
      };

      render() {
        const {
          itemWidth,
          channel,
          layoutName,
          isVideoPlaying,
          mainVideoId,
          isResponsive,
        } = this.props;

        return (
          <main
            className={classnames(styles.container, {
              [styles.responsive]: isResponsive,
            })}
            data-thumbnail-min-width={getThumbnailMinWidthAttribute(itemWidth)}
            data-hook="widget-container"
            data-channel-layout={layoutName}
            aria-label={this.props.t(
              'widget.accessibility.channel-videos-widget',
              {
                channelTitle: channel.title,
              },
            )}
            tabIndex="0"
          >
            {this.renderActions()}
            {isResponsive
              ? this.renderResponsiveSliderContent()
              : this.renderSliderContent()}

            <OpenFullscreenModalShare itemWidth={itemWidth} />
            <PaymentEvents
              onRent={this.setCurrentVideoFromPayment}
              onSale={this.setCurrentVideoFromPayment}
            />
            <LiveStartHandler
              playVideo={this.playVideo}
              isVideoPlaying={isVideoPlaying}
              selectedVideoId={mainVideoId}
            />
            <WidgetPerformanceLoggers />
          </main>
        );
      }
    },
  ),
);

export const SliderLayout = withFedopsLogger(
  withWindowSize(SliderLayoutComponent),
);
export default _.flow(withPlayerModuleLoader)(SliderLayout);
