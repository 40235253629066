import {UrlQueryParams, encodeQueryParams} from '@wix/panda-js-utils'
import {ModalActions, ModalResponse, SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import {setActiveElement} from '../actions/focus-handler'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'

export const MODAL_OPENED = 'MODAL_OPENED'

interface OpenModalProps {
  type: string
  params?: UrlQueryParams
}

export const openModal =
  ({type, params}: OpenModalProps) =>
  (
    dispatch: Function,
    _getState: GetCommonState,
    {wixCodeApi, compId}: CommonStoreExtraArgs,
  ): Promise<{message: {reason: string}}> => {
    const queryParams = params ? encodeQueryParams(params) : ''
    dispatch({type: MODAL_OPENED, modalType: type, params})
    return wixCodeApi.window.openPopup(
      `${SERVER_BASE_URL}/html/modal/${type}${queryParams}`,
      {
        theme: 'BARE',
        width: '100%' as any,
        height: '100%' as any,
        position: {
          origin: 'FIXED',
          placement: 'CENTER',
          x: 0,
          y: 0,
        },
      },
      compId,
    )
  }

interface OpenGeneralModalProps extends OpenModalProps {
  onConfirm?: (response?: any) => void
  onClose?: (response?: any) => void
  returnFocusElement?: string
}

export const openDialogModal =
  ({onConfirm, onClose, returnFocusElement, ...rest}: OpenGeneralModalProps) =>
  async (dispatch: Function) => {
    const response: ModalResponse = await dispatch(openModal(rest))
    if (response.message.reason === ModalActions.OK && onConfirm) {
      onConfirm(response)
    } else if (onClose) {
      onClose(response)
    }
    if (returnFocusElement) {
      dispatch(setActiveElement(returnFocusElement))
    }
  }
