import {HttpError} from '@wix/http-client'
import {AnyAction} from 'redux'
import {RegistrationErrorKey} from '../../../../commons/enums'
import {GET_ORDER, PLACE_ORDER, UPDATE_ORDER} from '../actions/placed-order'
import {RESERVE_TICKETS} from '../actions/reservation'
import {RESET_RSVP_ERROR, SEND_RSVP, UPDATE_RSVP} from '../actions/rsvp'
import {FormSubmitErrors} from '../types'

const getDefaultState = (): FormSubmitErrors => ({
  error: {},
  errorTime: null,
  canRetry: false,
})

export const formSubmitErrors = (state = getDefaultState(), action: AnyAction) => {
  switch (action.type) {
    case SEND_RSVP.FAILURE:
    case PLACE_ORDER.FAILURE:
    case UPDATE_ORDER.FAILURE:
    case UPDATE_RSVP.FAILURE:
      const error: HttpError = action.error
      return {
        ...state,
        error: error?.response?.data?.metadata ?? {error_key: RegistrationErrorKey.UNKNOWN_REGISTRATION_ERROR},
        canRetry: canRetryError(error),
        errorTime: new Date(),
      }
    case RESET_RSVP_ERROR:
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case GET_ORDER.SUCCESS:
    case RESERVE_TICKETS.REQUEST:
    case UPDATE_RSVP.SUCCESS:
      return {...state, error: {}, errorTime: null, canRetry: false}
    default:
      return state
  }
}

const canRetryError = (error: HttpError) => {
  return error?.response?.status > 500 || error?.response?.status === 429
}
