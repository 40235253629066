import classNames from 'classnames'
import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {classes} from './page-title.st.css'

export const PageTitle = ({mobile, ...props}) => (
  <Text
    typography={TextTypography.largeTitle}
    {...props}
    className={classNames(classes.root, {[classes.mobile]: mobile})}
    tagName="h2"
  />
)
