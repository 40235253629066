import type {ClientStatusPublic} from '@wix/cashier-common/dist/src/types/PaymentStatus'

export const ROOT_COMPONENT_ID = 'events-details-page-root'

export const WarmupStateKey = 'EventsPageInitialState'
export const EventSlugQueryOverrideParam = 'event-slug-override'

export enum UserRole {
  ADMIN = 'Admin',
  MEMBER = 'Member',
  Visitor = 'Visitor',
}

export enum FormStep {
  BuyerDetails = 'form',
  TicketsDetails = 'ticketsDetails',
  Policies = 'policies',
  Payment = 'payment',
}

export const CHECKOUT_STEPS = [FormStep.BuyerDetails, FormStep.TicketsDetails, FormStep.Policies, FormStep.Payment]

export const RSVP_STEPS = [FormStep.BuyerDetails, FormStep.Policies]

export interface SettingsEvents {
  navigate: DetailsSettingsNavigateActions
}

export enum DetailsPageSettingsEventsKeys {
  Navigate = 'navigate',
}

export enum DetailsSettingsNavigateActions {
  details = 'details',
  form = 'form',
}

export enum SEATING_ERROR {
  TICKET_LIMIT_REACHED = 'TICKET_LIMIT_REACHED',
}

export enum RESERVATION_ERROR {
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  PLACE_RESERVED = 'PLACE_RESERVED',
}

export const confirmPaymentSuccessStatuses: ClientStatusPublic[] = ['Approved', 'Pending', 'InProcess']
