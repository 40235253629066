import {getEventTitle, isDateTbd} from '@wix/wix-events-commons-statics'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {EventText} from '../../../../../commons/components/event-text'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import {DetailedEvent} from '../../types/state'
import s from './event-summary.scss'
import {EventSummaryProps} from '.'

export const EventSummary = ({event, mobile, t}: EventSummaryProps) => (
  <div className={classNames(s.root, {[s.mobile]: mobile})} data-hook={DH.EVENT_SUMMARY}>
    {mobile ? <MobileDate event={event} t={t} /> : <Date event={event} />}
    <div className={s.name}>
      <EventText dataHook={DH.EVENT_TITLE} extraClass={s.nameText}>
        {getEventTitle(event.event)}
      </EventText>
    </div>
  </div>
)

const Date = ({event}: {event: DetailedEvent}) => {
  const {shortStartDate} = useEventDateInformation(event.event.id)
  return (
    <div className={s.date}>
      <EventText>{shortStartDate}</EventText>
    </div>
  )
}

const MobileDate = ({event, t}: {event: DetailedEvent; t: Function}) => (
  <div className={s.mobileDateContainer}>
    {isDateTbd(event.event) ? <MobileTbdDate t={t} /> : <MobileScheduledDate event={event} />}
  </div>
)

const MobileTbdDate = ({t}) => (
  <div className={s.tbdDate}>
    <EventText extraClass={s.tbd}>{t('events.tbd-time')}</EventText>
  </div>
)

const MobileScheduledDate = ({event}: {event: DetailedEvent}) => {
  const {day, month} = useEventDateInformation(event.event.id)

  return (
    <div className={s.mobileDate}>
      <EventText extraClass={s.day}>{day}</EventText>
      <EventText extraClass={s.month} faded>
        {month}
      </EventText>
    </div>
  )
}
