import {VisitorType} from '@wix/events-types'
import {prependHTTP} from '@wix/panda-js-utils'
import {
  isExternal,
  isRecurringEvent,
  isRegistrationClosed,
  isRsvp,
  isSoldOut,
  isWaitlistOpen,
} from '@wix/wix-events-commons-statics'
import {RIBBON} from '../../../../commons/constants/ribbons'
import {isMobile, isSite} from '../../../../commons/selectors/environment'
import {isDetailsPageEnabled} from '../../../../commons/selectors/site-settings'
import {getDetailsTarget} from '../../../../commons/services/navigation'
import {WidgetComponentTextSettings} from '../../settingsParams'
import {State} from '../types/state'
import {getForcedRibbon, shouldForceShowRibbon} from './layout'
import {isRestrictedTo} from './members-enabled'
import {
  getComponentConfig,
  getWidgetType,
  isJoinWaitlistRibbonEnabled,
  isOfferRibbonEnabled,
  isOfferRibbonVisible,
  isRecurringRibbonEnabled,
  isRibbonEnabled,
  isRibbonVisible,
  isRsvpClosedRibbonEnabled,
  isSoldOutRibbonEnabled,
} from './settings'
import {isOwner} from './user'

export const getEventById = (state: State, eventId: string) => getEvents(state).find(event => event.id === eventId)

export const getEvents = (state: State): ExtendedEvent[] => {
  if (shouldShowRealEvents(state)) {
    return getRealEvents(state)
  }

  if (shouldShowDemoEvents(state)) {
    return getDemoEvents(state)
  }

  return []
}

export const hadEventsEver = (state: State) => state.siteSettings.firstEventCreated || hasRealEvents(state)

export const shouldShowDemoEvents = (state: State) =>
  (hadEventsEver(state) && !isSite(state) && !hasRealEvents(state)) ||
  (!hadEventsEver(state) && (isOwner(state) || !isSite(state)))

export const shouldShowRealEvents = (state: State) => hadEventsEver(state) && (isSite(state) || hasRealEvents(state))

export const getRealEvents = (state: State) => state.events.events
export const getRealEventsCount = (state: State) => getRealEvents(state).length
export const hasRealEvents = (state: State) => !!getRealEventsCount(state)

export const getDemoEvents = (state: State) =>
  (state.demoEvents[getWidgetType(getComponentConfig(state))] as ExtendedEvent[]) || []

export const getFirstEvent = (state: State) => getEvents(state)[0]

export const getEventsIds = (state: State) => getEvents(state).map(event => event.id)

const RIBBONS: {[key in RIBBON]: RibbonData} = {
  [RIBBON.SOLD_OUT]: {
    visible: true,
    ribbon: RIBBON.SOLD_OUT,
    defaultText: 'ribbon.soldOut',
    textProp: 'soldOutRibbonText',
    status: true,
  },
  [RIBBON.RSVP_CLOSED]: {
    visible: true,
    ribbon: RIBBON.RSVP_CLOSED,
    defaultText: 'ribbon.rsvpClosed',
    textProp: 'rsvpClosedRibbonText',
    status: true,
  },
  [RIBBON.JOIN_WAITLIST]: {
    visible: true,
    ribbon: RIBBON.JOIN_WAITLIST,
    defaultText: 'ribbon.joinWaitList',
    textProp: 'joinWaitlistRibbonText',
    status: true,
  },
  [RIBBON.MEMBERSHIP]: {
    visible: true,
    ribbon: RIBBON.MEMBERSHIP,
    defaultText: 'ribbon.memberOffer',
    textProp: 'membershipRibbonText',
    status: false,
  },
  [RIBBON.RECURRING]: {
    visible: true,
    ribbon: RIBBON.RECURRING,
    defaultText: 'ribbon.recurringDates',
    textProp: 'recurringRibbonText',
    status: false,
  },
}

interface RibbonData {
  visible: boolean
  ribbon?: RIBBON
  defaultText?: string
  textProp?: keyof WidgetComponentTextSettings
  status?: boolean
}

export const getRibbonData = (state: State, event: ExtendedEvent): RibbonData => {
  if (!isRibbonVisible(state, event)) {
    return {visible: false}
  }

  if (shouldForceShowRibbon(state, event)) {
    return getShowcaseRibbonData(state)
  }

  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)

  let ribbon: RibbonData = {visible: false}
  if (isRecurringRibbonEnabled(componentConfig, mobile) && isRecurringEvent(event)) {
    ribbon = RIBBONS[RIBBON.RECURRING]
  } else if (isSoldOutRibbonEnabled(componentConfig, mobile) && isSoldOut(event)) {
    ribbon = RIBBONS[RIBBON.SOLD_OUT]
  } else if (isRsvpClosedRibbonEnabled(componentConfig, mobile) && isRegistrationClosed(event)) {
    ribbon = RIBBONS[RIBBON.RSVP_CLOSED]
  } else if (isJoinWaitlistRibbonEnabled(componentConfig, mobile) && isRsvp(event) && isWaitlistOpen(event)) {
    ribbon = RIBBONS[RIBBON.JOIN_WAITLIST]
  } else if (isOfferRibbonEnabled(componentConfig, mobile) && isOfferRibbonVisible(state, event)) {
    ribbon = RIBBONS[RIBBON.MEMBERSHIP]
  }

  return ribbon
}

const getShowcaseRibbonData = (state: State): RibbonData => {
  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)
  const ribbon = getForcedRibbon(state)
  if (ribbon && isRibbonEnabled(ribbon, componentConfig, mobile)) {
    return RIBBONS[ribbon]
  }
  return {
    visible: false,
  }
}

export const isEventRestricted = (state: State, event: ExtendedEvent) =>
  !isDetailsPageEnabled(state.siteSettings) &&
  isRsvp(event) &&
  isRestrictedTo(event, state, VisitorType.MEMBER) &&
  !state.memberLoggedIn

export const hasEventDetailsUrl = (state: State, event: ExtendedEvent) =>
  isExternal(event) || (isSite(state) && !isEventRestricted(state, event))

export const getEventUrl = (
  state: State,
  event: ExtendedEvent,
  baseUrl: string,
  relativeUrl: string,
  queryParams: string,
) => {
  if (isExternal(event)) {
    return prependHTTP(event.registration.external.registration)
  }
  return getEventDetailsPageUrl(state, event, baseUrl, relativeUrl, queryParams)
}

export const getEventDetailsPageUrl = (
  state: State,
  event: ExtendedEvent,
  baseUrl: string,
  relativeUrl: string,
  queryParams: string,
) => {
  if (isSite(state) && relativeUrl && !isEventRestricted(state, event)) {
    return `${baseUrl}${relativeUrl}/${getDetailsTarget(event, isDetailsPageEnabled(state.siteSettings))}${queryParams}`
  }
  return null
}

export const hasMoreEvents = (state: State) => state.events.hasMore
export const isMoreEventsLoading = (state: State) => state.events.moreLoading

export const getSelectedEvents = (state: State) => getEventsIds(state)
