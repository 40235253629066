import { IMemberDetails, IUser } from '@wix/yoshi-flow-editor';
import { IWixStatic } from '@wix/tpa-settings';

const getEmail = async (currentUser: IUser) => {
  try {
    return await currentUser.getEmail();
  } catch (e) {
    const Wix = (global as unknown as { Wix: IWixStatic }).Wix;
    if (Wix && Wix.currentMember) {
      /*
       *  TODO: wixCodeApi.user.currentUser.getEmail() is buggy;
       *  and throws an error if wixCodeApp isn't installed for a user
       *  that said this fallback allows to get user email at least in fs-modals/mobile overlays
       * */
      const member = await new Promise<IMemberDetails>((resolve) => {
        Wix.currentMember((memberDetails) => {
          resolve(memberDetails);
        });
      });
      return member.email;
    }
    return ' ';
  }
};

export const getCurrentSiteUser = async (currentUser: IUser) => {
  if (currentUser && currentUser.loggedIn) {
    return {
      id: currentUser.id,
      email: await getEmail(currentUser),
    };
  }

  return null;
};
