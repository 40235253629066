import {
  ISiteTextPreset,
  IWixStyleFont,
  StyleParamType,
  createStylesParams,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings'

const getDefaultFont = (name: keyof ISiteTextPreset, options: Partial<IWixStyleFont> = {}) => {
  return ({textPresets}: {textPresets: ISiteTextPreset}) => {
    const {value: _, ...font} = wixFontParam(name, options)({textPresets})
    return font as IWixStyleFont
  }
}

export default createStylesParams<{
  selectedTabColor: StyleParamType.Color
  tabsColor: StyleParamType.Color
  tabsFont: StyleParamType.Font
  pageTitleColor: StyleParamType.Color
  pageTitleFont: StyleParamType.Font
  descriptionColor: StyleParamType.Color
  descriptionFont: StyleParamType.Font
  eventTextColor: StyleParamType.Color
  eventTextFont: StyleParamType.Font
  eventLinkColor: StyleParamType.Color
  eventLinkFont: StyleParamType.Font
  eventLinkButtonColor: StyleParamType.Color
  eventLinkButtonFont: StyleParamType.Font
  eventLinkButtonBorderColor: StyleParamType.Color
  eventLinkButtonBorderRadius: StyleParamType.Number
  eventLinkButtonBorderWidth: StyleParamType.Number
  noEventsTextColor: StyleParamType.Color
  noEventsTextFont: StyleParamType.Font
  noEventsButtonFont: StyleParamType.Font
  noEventsButtonColor: StyleParamType.Color
  noEventsButtonBorderRadius: StyleParamType.Number
  noEventsButtonBorderColor: StyleParamType.Color
  noEventsButtonBorderWidth: StyleParamType.Number
  backgroundColor: StyleParamType.Color
  backgroundBorderColor: StyleParamType.Color
  backgroundBorderWidth: StyleParamType.Number
  dividerColor: StyleParamType.Color
  dividerWidth: StyleParamType.Number
}>({
  selectedTabColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  tabsColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  tabsFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Page-title', {
      size: 28,
    }),
  },
  descriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  descriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  eventTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  eventLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventLinkButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  eventLinkButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  eventLinkButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  eventLinkButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  eventLinkButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  noEventsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  noEventsTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 24,
    }),
  },
  noEventsButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: getDefaultFont('Body-M', {
      size: 16,
    }),
  },
  noEventsButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  noEventsButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  noEventsButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  noEventsButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  backgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  backgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
})
