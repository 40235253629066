import classNames from 'classnames'
import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {classes} from './description.st.css'

export const Description = ({mobile, ...props}) => (
  <Text
    typography={TextTypography.runningText}
    {...props}
    className={classNames(classes.root, {[classes.mobile]: mobile})}
  />
)
