import {handleKeyPressEnterOrSpace} from '@wix/panda-js-utils'
import {getAvatars, getExtraGuestsCount, isTicketed} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {AvatarGroup, AvatarGroupSize, TextButtonPriority} from 'wix-ui-tpa/cssVars'
import {classes} from './members.st.css'
import {MembersProps} from './index'

const MAX_AVATARS = 7
const MAX_ONLY_ANONYMOUS_AVATARS = 3

export const Members = ({totalGuests, members, event, mobile, openMembersModal, t}: MembersProps) => {
  const items = getAvatars(members, totalGuests, !isTicketed(event), MAX_AVATARS)
  const hasMemberAvatars = items.filter(item => item.name).length
  const hasMembers = members.length

  const maxAvatars = hasMemberAvatars ? MAX_AVATARS : MAX_ONLY_ANONYMOUS_AVATARS
  const extraGuests = getExtraGuestsCount(totalGuests, maxAvatars)
  const label =
    !hasMembers && extraGuests ? t('members.moreGuestsLink', {amount: extraGuests}) : t('members.seeAllGuestsLink')

  const onClick = () => openMembersModal(event.id)

  return (
    <div
      className={classNames(classes.root, mobile ? classes.mobile : '')}
      onClick={onClick}
      onKeyPress={handleKeyPressEnterOrSpace(onClick)}
      tabIndex={0}
      data-hook={DH.EVENT_DETAILS_MEMBERS}
      role="link"
    >
      <AvatarGroup
        className={classes.avatars}
        maxAmount={maxAvatars}
        items={items}
        size={mobile ? AvatarGroupSize.medium : AvatarGroupSize.large}
      >
        <AvatarGroup.TextButton
          tabIndex={-1}
          className={classes.link}
          priority={mobile ? TextButtonPriority.link : TextButtonPriority.primary}
        >
          {label}
        </AvatarGroup.TextButton>
      </AvatarGroup>
    </div>
  )
}
