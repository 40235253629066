import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import getOldController from './Widget/old/controller';

const createController: CreateControllerFn = async (params) => {
  const getController = getOldController;

  return getController(params);
};

export default createController;
