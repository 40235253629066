import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, withTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {DynamicStyle} from '../../../commons/components/dynamic-style'
import {StylesForAllBreakpointsProvider} from '../../../commons/hooks/styles-for-all-breakpoints'
import {ROOT_COMPONENT_ID} from '../constants'
import {getButtonStyles, getTextAlignment} from '../custom-css-vars'
import {useSettings} from '../hooks/settings'
import settingsParams from '../settingsParams'
import {Button} from './components/button'
import {EmptyState} from './components/empty-state'
import {Filters} from './components/filters'
import {Header} from './components/header'
import {Modals} from './components/modals'
import {NoResults} from './components/no-results'
import {Schedule} from './components/schedule'
import {ItemDivider} from './components/schedule/divider'
import {WidgetPropsContext} from './hooks/widget-props'
import {WidgetProps} from './interfaces'
import {isEmptyStateVisible, isNoResultsStateVisible} from './selectors/schedule'
import {classes} from './widget.st.css'

export default withTranslation()((props: WidgetProps) => {
  const {
    t,
    resetFilter,
    eventDetailsUrl,
    navigate,
    days,
    host: {id},
  } = props
  const {isViewer, isMobile} = useEnvironment()
  const settings = useSettings()
  const emptyStateVisible = isEmptyStateVisible(props)

  const buttonStyleVars = getButtonStyles('fallbackResolvedButton', settings.get(settingsParams.buttonStyle))
  const buttonStyleVarsStringified = Object.entries(buttonStyleVars)
    .map(([key, value]) => `--${key}: ${value}`)
    .join(';')

  return (
    <WidgetPropsContext.Provider value={props}>
      <StylesForAllBreakpointsProvider>
        <DynamicStyle namespace={id}>
          {`
           $namespace$ {
              --fallbackDateAndLocationVisibility: ${
                settings.get(settingsParams.showDateAndLocation) ? 'block' : 'none'
              };
              --fallbackButtonVisibility: ${settings.get(settingsParams.showButton) ? 'inline-flex' : 'none'};
              --fallbackFiltersVisibility: ${settings.get(settingsParams.showFilters) ? 'flex' : 'none'};
              --fallbackDurationVisibility: ${settings.get(settingsParams.showDuration) ? 'block' : 'none'};
              --fallbackHeaderTextAlignment: ${getTextAlignment(settings.get(settingsParams.headerTextAlignment))};
              ${buttonStyleVarsStringified}
          }
        `}
        </DynamicStyle>
        <div
          id={ROOT_COMPONENT_ID}
          className={classNames(classes.root, classes.container, {[classes.mobile]: isMobile})}
          data-hook={DH.ROOT_NODE}
        >
          {emptyStateVisible ? (
            <EmptyState />
          ) : (
            <div className={classes.content}>
              <Header />
              <Filters />
              {isNoResultsStateVisible(props) ? (
                <NoResults onLinkClick={resetFilter} t={t} />
              ) : (
                <>
                  <Schedule days={days} />
                  {isMobile && (
                    <>
                      <ItemDivider />
                      <div className={classes.buttonContainer}>
                        <Button
                          fullWidth={true}
                          eventDetailsUrl={eventDetailsUrl}
                          navigate={navigate}
                          isSite={isViewer}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {emptyStateVisible ? null : <Modals />}
      </StylesForAllBreakpointsProvider>
    </WidgetPropsContext.Provider>
  )
})
