import {isMembersInstalled} from '../../../../commons/utils/members-api'
import {createAsyncAction} from '../services/redux-toolkit'

export const checkMembersAreaInstallation = createAsyncAction<boolean>(
  'CHECK_MEMBERS_AREA_INSTALLATION',
  (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
      },
    },
  ) => isMembersInstalled(wixCodeApi),
)

export type CheckMembersAreaInstallation = typeof checkMembersAreaInstallation
