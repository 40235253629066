import {EventDetailsAlignment} from '@wix/wix-events-commons-statics'
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor'
import {remapColor, remapFont} from '../../commons/custom-css-utils'
import {BUTTON_STYLE} from './settingsParams'
import scheduleStyleParams from './stylesParams'

export const customCssVars: CustomCssVarsFn = ({styleParams}) => {
  const styles = {
    get: param => {
      return (
        styleParams.booleans[param.key] ??
        styleParams.numbers[param.key] ??
        styleParams.colors[param.key] ??
        styleParams.fonts[param.key]
      )
    },
  }

  const getVisibility = (key, param, style = 'block') => {
    if (styles.get(param) === false) {
      return {[key]: 'none'}
    } else if (styles.get(param) === true) {
      return {[key]: style}
    }
    return undefined
  }

  const getHeaderAlignment = () => {
    const alignment = styles.get(scheduleStyleParams.headerTextAlignment)
    if (alignment !== undefined) {
      return {headerTextAlignmentResolved: getTextAlignment(alignment)}
    }
    return undefined
  }

  return {
    ...getVisibility('dateAndLocationVisibility', scheduleStyleParams.showDateAndLocation),
    ...getVisibility('buttonVisibility', scheduleStyleParams.showButton, 'inline-flex'),
    ...getVisibility('durationVisibility', scheduleStyleParams.showDuration),
    ...getVisibility('filtersVisibility', scheduleStyleParams.showFilters, 'flex'),
    ...getHeaderAlignment(),
    ...getButtonStyles('resolvedButton', styles.get(scheduleStyleParams.buttonStyle)),
  }
}

export const getTextAlignment = (alignment: EventDetailsAlignment) => {
  if (alignment === EventDetailsAlignment.LEFT) {
    return 'left'
  } else if (alignment === EventDetailsAlignment.RIGHT) {
    return 'right'
  } else if (alignment === EventDetailsAlignment.CENTER) {
    return 'center'
  }
}

export const getButtonStyles = (key: string, buttonStyle: BUTTON_STYLE) => {
  if (buttonStyle === undefined) {
    return undefined
  }
  const hollow = [BUTTON_STYLE.HOLLOW, BUTTON_STYLE.HOLLOW_ROUNDED].includes(buttonStyle)

  return {
    ...remapFont(`${key}Font`, 'buttonTextFont'),
    ...remapColor(`${key}Color`, hollow ? 'hollowButtonTextColor' : 'fullButtonTextColor'),
    ...remapColor(`${key}BackgroundColor`, hollow ? null : 'buttonBackgroundColor'),
    ...remapColor(`${key}BorderColor`, hollow ? 'buttonBorderColor' : null),
    [`${key}BorderWidth`]: hollow ? 'var(--buttonBorderWidth)' : '0',
    [`${key}BorderRadius`]: 'var(--buttonBorderRadius)',
  }
}
