import {
  LIST_LAYOUT,
  WIDGET_TYPE,
  hasGuestsGoing,
  isInternal,
  isRecurringEvent,
  isRsvp,
  isWithoutRegistration,
} from '@wix/wix-events-commons-statics'
import {RIBBON} from '../../../../commons/constants/ribbons'
import {isEditor, isMobile} from '../../../../commons/selectors/environment'
import {WidgetComponentConfig} from '../../types'
import {State} from '../types/state'
import {getListLayout} from './list-settings'
import {isButtonLayout} from './single-settings'

// Do not use for keys that are not global ("isGlobal: true" when setting the value).
export const getValue = (componentConfig: WidgetComponentConfig, mobile: boolean, key: string) => {
  const value = componentConfig.settings[key]

  if (mobile) {
    return componentConfig.settings[key + '▶︎m'] ?? value
  }

  return value
}

export const isResponsive = (componentConfig: WidgetComponentConfig) => componentConfig.settings.responsive

export const isMultiEventWidget = (componentConfig: WidgetComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.LIST

/**
 * @deprecated Should only be used as legacy fallback for more specific ribbons
 */

export const isStatusRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRibbon')

export const isJoinWaitlistRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showJoinWaitlistRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isSoldOutRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showSoldOutRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isAnyStatusRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  isSoldOutRibbonEnabled(componentConfig, mobile) ||
  isJoinWaitlistRibbonEnabled(componentConfig, mobile) ||
  isRsvpClosedRibbonEnabled(componentConfig, mobile)

export const getWidgetType = (componentConfig: WidgetComponentConfig) => componentConfig.settings.widgetType

export const isSingleEventWidget = (componentConfig: WidgetComponentConfig) =>
  getWidgetType(componentConfig) === WIDGET_TYPE.SINGLE

export const isRsvpClosedRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRsvpClosedRibbon') ?? isStatusRibbonEnabled(componentConfig, mobile)

export const isTitleEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showTitle

export const isRibbonEnabled = (ribbon: RIBBON, componentConfig: WidgetComponentConfig, mobile: boolean) => {
  return {
    [RIBBON.SOLD_OUT]: isSoldOutRibbonEnabled,
    [RIBBON.RECURRING]: isRecurringRibbonEnabled,
    [RIBBON.JOIN_WAITLIST]: isJoinWaitlistRibbonEnabled,
    [RIBBON.MEMBERSHIP]: isOfferRibbonEnabled,
    [RIBBON.RSVP_CLOSED]: isRsvpClosedRibbonEnabled,
  }[ribbon](componentConfig, mobile)
}

export const isShareOptionsEnabled = (componentConfig: WidgetComponentConfig) =>
  componentConfig.settings.showShareOptions

export const isRsvpTitleEnabled = (componentConfig: WidgetComponentConfig) => componentConfig.settings.showRSVPTitle

export const isRecurringRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showRecurringRibbon')

export const isAnyRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  isSoldOutRibbonEnabled(componentConfig, mobile) ||
  isJoinWaitlistRibbonEnabled(componentConfig, mobile) ||
  isRsvpClosedRibbonEnabled(componentConfig, mobile) ||
  isRecurringRibbonEnabled(componentConfig, mobile) ||
  isOfferRibbonEnabled(componentConfig, mobile)

export const isOfferRibbonEnabled = (componentConfig: WidgetComponentConfig, mobile: boolean) =>
  getValue(componentConfig, mobile, 'showOfferRibbon')

export const getComponentConfig = (state: State) => state.component

export const getRecurringFilter = (state: State) => getComponentConfig(state).settings.recurringFilter

export const isListLayout = (componentConfig: WidgetComponentConfig) =>
  isMultiEventWidget(componentConfig) && getListLayout(componentConfig) === LIST_LAYOUT.LIST

export const isRibbonVisible = (state: State, event: wix.events.Event) => {
  const componentConfig = getComponentConfig(state)
  const mobile = isMobile(state)
  const ribbonEnabled = isAnyRibbonEnabled(componentConfig, mobile)

  const hasRegistrationOrRecurring =
    (isRecurringRibbonEnabled(componentConfig, mobile) && isRecurringEvent(event)) || !isWithoutRegistration(event)

  return ribbonEnabled && hasRegistrationOrRecurring && !isButtonLayout(componentConfig)
}

export const isOfferRibbonVisible = (state: State, event: ExtendedEvent) =>
  isOfferRibbonEnabled(getComponentConfig(state), isMobile(state)) && event.memberOffer

export const isMembersInstalled = (state: State) => state.membersEnabled

export const isMembersVisible = (state: State, event: ExtendedEvent) =>
  isMembersInstalled(state) &&
  isInternal(event) &&
  (hasGuestsGoing(event.totalGuests, isRsvp(event)) || isEditor(state))
