import {AnyAction} from 'redux'
import {
  GET_ORDER,
  PLACE_ORDER,
  UPDATE_ORDER,
  expressCheckout,
  getOrderTicketsDownloadUrl,
} from '../actions/placed-order'
import {RESERVE_TICKETS} from '../actions/reservation'
import {PlacedOrderState} from '../types'

const defaultState: PlacedOrderState = {
  order: undefined,
  loadingTicketsUrl: true,
  orderPageUrl: null,
  chargeToken: null,
  clientStatus: null,
}

export const placedOrder = (state = defaultState, action: AnyAction): PlacedOrderState => {
  switch (action.type) {
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case GET_ORDER.SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case RESERVE_TICKETS.REQUEST:
      return defaultState
    case getOrderTicketsDownloadUrl.pending.toString(): {
      return {...state, loadingTicketsUrl: true}
    }
    case getOrderTicketsDownloadUrl.rejected.toString(): {
      return defaultState
    }
    case getOrderTicketsDownloadUrl.fulfilled.toString(): {
      const {loadingTicketsUrl, downloadTicketsUrl} = action.payload
      return {...state, loadingTicketsUrl, downloadTicketsUrl}
    }
    case expressCheckout.fulfilled.toString(): {
      const {clientStatus} = action.payload
      return {...state, clientStatus}
    }
    default:
      return state
  }
}
